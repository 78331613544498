html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Custom scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: lighten(#555, 40%) #f1f1f1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  border: 4px solid lighten(#555, 40%);
  border-radius: 10px;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  border: 4px solid #555;
}

.form-label {
  font-size: 14px;
}

.form-value {
  font-size: 16px;
}

.display-linebreak {
  white-space: pre-line;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.only-print {
  display: none !important;
}

@media print {
  .only-print {
    display: initial !important;
  }

  .page-break-after {
    page-break-after: always;
  }

  .page-break-before {
    page-break-before: always;
  }
}
